<template>
	<header class="c-page-header" :class="`c-page-header--layout-${layout}`">
		<div
			class="c-page-header__main relative px-layout-margin text-white"
			:class="{
				'pt-site-header': !$scopedSlots.custom,
				'pb-sm/v':
					['default', 'publication'].includes(layout) &&
					!$scopedSlots.custom,
				'grid grid-cols-12 pb-0':
					layout === 'list' && !$scopedSlots.custom,
			}"
		>
			<slot name="custom">
				<HeaderGraphic
					v-if="headerGraphic > -2"
					class="c-page-header__header-graphic"
					style="z-index: -1"
					:index="headerGraphic"
				/>

				<!-- Slot things in -->
				<div
					v-if="$scopedSlots.top || computedLabel || computedAreas"
					class="mb-64"
					:class="{
						'>=1024:mb-24': ['default', 'publication'].includes(
							layout
						),
						'col-span-12': layout === 'list',
					}"
				>
					<slot name="top">
						<div class="flex flex-wrap items-center gap-8">
							<small v-if="computedLabel" class="pr-3xs/h">
								<span
									class="
										inline-block
										font-medium
										uppercase
										text-label-sm
									"
									v-text="computedLabel"
								></span>

								<span
									v-if="computedDate"
									class="
										c-page-header__date-stamp
										inline-block
										font-medium
										text-label-sm
										uppercase
									"
									v-text="computedDate"
								></span>
							</small>

							<AreaReferences
								v-if="computedAreas"
								class="hidden >=1024:contents"
								:items="computedAreas"
								invert
							/>
						</div>
					</slot>
				</div>
				<div v-else class="pt-48 >=1024:hidden"></div>

				<!-- Heading -->
				<div
					v-if="intro.title"
					:class="{
						'col-span-12 >=1024:col-span-4': layout === 'list',
					}"
				>
					<AreaIcon
						v-if="iconUrl"
						class="
							inline-block
							w-header-icon
							h-header-icon
							align-baseline
							mr-xs/h
						"
						style="margin-block: calc(4px - 0.4em)"
						invert
						:src="iconUrl"
					/>
					<BaseH1
						class="inline"
						:small="layout === 'list'"
						v-html="intro.title"
					/>
				</div>

				<!-- Teaser -->
				<div
					v-if="
						(intro.teaser && layout === 'list') ||
						(computedSubTitle &&
							['default', 'publication'].includes(layout)) ||
						$scopedSlots.afterTeaser ||
						computedAuthors
					"
					class="mt-20"
					:class="{
						'>=1024:mt-sm/v': ['default', 'publication'].includes(
							layout
						),
						'>=1024:mt-0 col-span-12 >=1024:col-start-5 >=1024:col-span-8 >=1024:pl-16':
							layout === 'list',
					}"
				>
					<!--
						Only show the teaser in the header when on list pages.
						Though other pages may get a sub-title in this location,
						which we will need to insert.
					-->
					<p
						v-if="intro.teaser && layout === 'list'"
						class="block text-teaser whitespace-pre-wrap"
						v-html="intro.teaser"
					></p>
					<p
						v-if="
							computedSubTitle &&
							['default', 'publication'].includes(layout)
						"
						class="block text-teaser whitespace-pre-wrap"
						:class="{
							'font-light': ['default', 'publication'].includes(
								layout
							),
						}"
						v-html="computedSubTitle"
					></p>

					<!-- Slot things in right after the teaser -->
					<slot
						v-if="$scopedSlots.afterTeaser || computedAuthors"
						name="afterTeaser"
					>
						<small
							v-for="(group, index) in computedAuthors"
							:key="index"
							class="
								flex flex-wrap
								>=1024:flex-nowrap
								items-baseline
								gap-x-12
								align-baseline
								text-body-xs
								font-medium
								mt-xs/v
							"
						>
							<h2
								v-if="group.title"
								class="uppercase"
								v-text="group.title + ':'"
							></h2>

							<ul
								class="
									flex
									items-start
									flex-wrap
									gap-x-12
									align-baseline
								"
							>
								<template
									v-for="(
										item, itemIndex
									) in group.items.filter(
										(item) => item.value
									)"
								>
									<template
										v-for="(value, nameIndex) in item.value"
									>
										<li
											v-if="value.url"
											:key="
												itemIndex +
												'-' +
												nameIndex +
												'-link'
											"
										>
											<NuxtLinkExt
												:to="value.url"
												class="underline"
												style="
													text-underline-offset: 0.2em;
												"
												v-text="
													[
														value.name,
														value.comment &&
															`(${value.comment})`,
													]
														.filter(Boolean)
														.join(' ')
												"
											/>
										</li>
										<li
											v-else
											:key="itemIndex + '-' + nameIndex"
											v-text="
												[
													value.name,
													value.comment &&
														`(${value.comment})`,
												]
													.filter(Boolean)
													.join(' ')
											"
										></li>
									</template>
								</template>
							</ul>
						</small>
					</slot>
				</div>

				<!-- Slot things in -->
				<div
					:class="{
						'col-span-12': layout === 'list',
					}"
				>
					<slot>
						<AreaReferences
							v-if="computedAreas"
							class=">=1024:hidden mt-2xs/v"
							:items="computedAreas"
							invert
						/>
					</slot>
				</div>
			</slot>
		</div>

		<!-- Image -->
		<figure
			v-if="
				intro.image &&
				['default', 'publication'].includes(layout) &&
				!$scopedSlots.custom
			"
			class="flex flex-col justify-center items-center"
		>
			<BaseImg
				class="w-visual-screen min-h-hero-image max-h-hero-image"
				:src="intro.image.cropUrl || intro.image.url"
				:alt="intro.image.altText"
				:sizes="nuxtImgSizes('<1441:100vw')"
				:width="intro.image.width"
				:height="intro.image.height"
				:ratio="1440 / 658"
				:img-attrs="{
					fetchpriority: 'high',
					class: 'object-cover',
				}"
				:credits="intro.image.credits"
				:quality="80"
			/>
			<figcaption
				v-if="intro.imageText"
				class="
					w-full
					px-layout-margin
					py-2xs/v
					text-body-xs text-gray-darker
				"
				v-text="intro.imageText"
			></figcaption>
		</figure>
	</header>
</template>

<script>
import HeaderGraphic from '~/components/main/HeaderGraphic';
import AreaIcon from '~/components/main/AreaIcon';
import AreaReferences from '~/components/main/AreaReferences';
import { nuxtImgSizes } from '~/assets/js/utilities/helpers';

export default {
	name: 'PageHeader',

	components: {
		HeaderGraphic,
		AreaIcon,
		AreaReferences,
	},

	props: {
		intro: {
			type: Object,
			default: () => ({}),
		},
		headerGraphic: {
			type: Number,
			default: -2,
		},
		iconUrl: {
			type: String,
			default: '',
		},
		details: {
			type: Object,
			default: () => ({}),
		},

		// List pages use one layout and content pages another
		layout: {
			type: String,
			default: 'default',
			validator(value) {
				return ['default', 'list', 'publication'].includes(value);
			},
		},
	},

	computed: {
		computedLabel() {
			if (this.layout === 'list') {
				return '';
			}
			return this.details?.label || '';
		},

		computedDate() {
			if (this.layout === 'list') {
				return '';
			}
			return this.details?.time || this.details?.date || '';
		},

		computedAreas() {
			if (this.layout === 'list') {
				return null;
			}
			return this.details?.areas?.length ? this.details.areas : null;
		},

		computedSubTitle() {
			return this.intro?.subTitle || '';
		},

		computedAuthors() {
			const array = [this.details?.people]
				.filter(Boolean)
				.filter(
					(group) => group?.items?.length || group?.fields?.length
				);
			return array.length ? array : null;
		},
	},

	methods: {
		nuxtImgSizes,
	},
};
</script>

<style lang="postcss">
.c-page-header__main:before {
	@apply absolute bg-theme;
	content: '';
	left: calc(var(--margin, 0px) * -1);
	top: 0;
	width: calc(100% + var(--margin, 0px) * 2);
	height: 100%;
	z-index: -1;
}
.c-page-header__header-graphic {
	@apply absolute;
	top: 0;
	left: auto;
	right: calc(var(--margin, 0px) * -1);
	width: calc(100% + var(--margin, 0px) * 2);
	height: 100%;
	z-index: -1;
}
.c-page-header__date-stamp:not(:first-child):before {
	content: ' — ' / '';
	opacity: 0.5;
}
@screen >=1441 {
	.c-page-header__main {
		--margin: (
				var(--visual-viewport-width, 100vw) -
					var(--theme-layout-max, 1440px)
			) / 2;
	}
}

@screen print {
	.c-page-header,
	.c-page-header__main > * {
		@apply text-text;
	}
	.c-page-header__main {
		@apply contents;

		&:before {
			@apply hidden;
		}
	}
	.c-page-header__header-graphic {
		@apply hidden;
	}
}
</style>
