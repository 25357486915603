<template>
	<div v-if="show" class="c-page-progress-bar-toc">
		<LongReadTarget
			v-if="tocToTop"
			container-id="page-top"
			:title="tocToTop"
			:forced-order="[0, 0, 0, 0, 0, 0]"
		/>

		<!-- Button -->
		<DropdownButton
			id="table-of-contents"
			ref="button"
			aria-labelledby="table-of-contents"
			:aria-owns="`c-page-progress-bar-toc__option-list-${_uid}`"
			:value="activeID"
			class="
				text-list-title
				px-layout-margin
				w-full
				>=1024:w-auto
				h-full
				flex
				items-center
				text-left
				transition-colors
				duration-200
			"
			:class="{
				'bg-red-80': isExpanded,
			}"
			@focus="onFocus"
			@state:toggle="onStateToggle"
			@change="selectTarget"
			@update:activedescendant="scrollToActiveDescendant"
		>
			<span class="relative inline-flex items-center">
				<SvgIconWedge
					:class="[
						'flex-shrink-0 flex-grow-0',
						'mr-2xs/h w-12 h-8 mt-1',
						{ 'transform -rotate-180 -mt-1': isExpanded },
					]"
				/>

				<TransitionExt name="page-progress-bar-toc" duration="200">
					<span
						:key="buttonText"
						class="c-page-progress-bar-toc__label absolute"
						v-html="buttonText"
					></span>
				</TransitionExt>
				<span
					key="spacetaker"
					class="invisible"
					v-html="longestButtonText"
				></span>
			</span>
		</DropdownButton>

		<!-- List -->
		<Transition name="t-page-progress-bar-toc__option-list">
			<DropdownOptionList
				:id="`c-page-progress-bar-toc__option-list-${_uid}`"
				v-slot="{
					activedescendant,
					selectedValues,
					isNavigatingByKeyboard,
				}"
				class="
					c-page-progress-bar-toc__option-list
					bg-red-80
					absolute
					left-0
					top-full
					w-full
					pt-6
					pb-lg/v
					overscroll-none
					overflow-y-auto
					origin-top
				"
			>
				<DropdownOption
					v-for="(item, index) in data.targets"
					:id="item.tocItemId"
					:key="item.tocItemId"
					:value="item.id"
					class="text-body px-layout-margin hover:bg-red-90"
					:class="{
						'bg-red-90':
							activedescendant === item.tocItemId &&
							isNavigatingByKeyboard,
					}"
				>
					<span
						class="
							inline-block
							w-full
							pl-24
							>=768:pl-0
							pb-12
							border-b border-white
							hover:border-opacity-100
						"
						:class="{
							'pt-12': index === 0 && !tocToTop,
							'pt-20': index > 0 || tocToTop,
							'border-opacity-50':
								!(
									activedescendant === item.tocItemId &&
									isNavigatingByKeyboard
								) &&
								!(
									selectedValues &&
									selectedValues.includes(item.id)
								),
						}"
					>
						<span
							class="c-page-progress-bar-toc__item-text relative"
							:class="{
								'c-page-progress-bar-toc__item-text--selected':
									selectedValues &&
									selectedValues.includes(item.id),
							}"
						>
							{{ item.title }}
						</span>
					</span>
				</DropdownOption>
			</DropdownOptionList>
		</Transition>
	</div>
</template>

<script>
import { LongReadTarget } from '~/components/shared/LongRead';
import {
	DropdownButton,
	DropdownOptionList,
	DropdownOption,
} from '~/components/shared/Dropdown';
import focusOverlay from '~/assets/js/focus-overlay.js';
import SvgIconWedge from '~/assets/svgs/icon-wedge.svg?inline';
import { scrollToElement } from '~/assets/js/utilities/helpers.js';

export default {
	name: 'PageProgressBarToc',

	components: {
		LongReadTarget,
		DropdownButton,
		DropdownOptionList,
		DropdownOption,
		SvgIconWedge,
	},

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},

		actions: {
			type: Object,
			default: () => ({}),
		},

		placeholder: {
			type: String,
			default: '',
		},

		tocToTop: {
			type: String,
			default: '',
		},
	},

	data() {
		const isInitiallyExpanded =
			typeof window !== 'undefined' ? window.innerWidth >= 1024 : true;
		return {
			isInitiallyExpanded,
			isExpanded: false,
			hasScrolledOnce: false,
			hasBeenClosedOnce: false,
		};
	},

	computed: {
		show() {
			return this.data?.targets?.length;
		},
		buttonText() {
			if (this.isExpanded && this.placeholder) {
				return this.placeholder;
			}
			if (
				this.placeholder &&
				this.data?.activeTarget &&
				this.data?.targets?.indexOf?.(this.data.activeTarget) === 0 &&
				!this.data.activeTarget.inViewport &&
				!this.data.activeTarget.aboveViewport
			) {
				return this.placeholder;
			}

			if (
				this.placeholder &&
				this.data?.activeTarget?.id === 'page-top'
			) {
				return this.placeholder;
			}

			return this.data?.activeTarget?.title || this.placeholder;
		},
		activeID() {
			return this.data?.activeTarget?.id;
		},
		longestButtonText() {
			return (
				this.data?.targets?.reduce?.((longest, target) => {
					return target.title.length > longest.length
						? target.title
						: longest;
				}, '') || this.buttonText
			);
		},
	},

	mounted() {
		window.setTimeout(() => {
			this.onHashchange({ newURL: window.location.href });

			if (
				window.innerWidth >= 1024 &&
				this.isInitiallyExpanded &&
				!this.checkIsScrolledDown()
			) {
				const { button } = this.$refs;
				button?.toggleOwnedLists?.(true);
				window.addEventListener('scroll', this.onScroll);
				window.addEventListener('resize', this.onScroll);
			}
		}, 200);
		window.addEventListener('hashchange', this.onHashchange);
	},

	beforeDestroy() {
		window.removeEventListener('hashchange', this.onHashchange);
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener('resize', this.onScroll);
	},

	methods: {
		onStateToggle(event) {
			if (!event) {
				this.hasBeenClosedOnce = true;
			}

			this.isExpanded = event;
			if (this.hasBeenClosedOnce || !this.isInitiallyExpanded) {
				window.setTimeout(() => {
					const list = document.getElementById(
						`c-page-progress-bar-toc__option-list-${this._uid}`
					);
					if (event && list) {
						try {
							list.scrollIntoView({
								behavior: this.checkPreferReducedMotion()
									? 'auto'
									: 'smooth',
								block: 'nearest',
							});
						} catch (e) {
							list.scrollIntoView();
						}
					}
				}, 0);
			}

			// Continue the emit
			this.$emit('state:toggle', event);
		},

		onHashchange({ newURL }) {
			const { button } = this.$refs;
			if (newURL && button?.$el) {
				const { hash } = new URL(newURL);
				const target = this.data?.targets?.find?.((target) => {
					if (`#${target.tocItemId}` === hash) {
						this.actions?.setActiveTarget?.(target);
						return true;
					}
					return false;
				});
				if (target) {
					button.$el?.focus?.();
					button.toggleOwnedLists(true);
					button.activedescendant = target.tocItemId;
				}
			}
		},
		onFocus() {
			if (focusOverlay?.isKeyboardNavigation) {
				const { button } = this.$refs;
				button?.toggleOwnedLists?.(true);
			}
		},

		selectTarget(id) {
			const targetEl = id && document.getElementById(id);
			if (targetEl) {
				this.setFocus(targetEl);
				if (this.$route.hash !== `#${id}`) {
					this.$router.push(`#${id}`);
				}
				scrollToElement(targetEl, {
					behavior:
						this.checkPreferReducedMotion() ||
						window.innerWidth < 1024
							? 'auto'
							: 'smooth',
				});
			}
		},

		setFocus(el) {
			const { tabIndex } = el;
			const hasTabIndex = el.hasAttribute('tabindex');
			el.tabIndex = -1;

			try {
				el.focus({
					preventScroll: true,
				});
			} catch (e) {
				const { scrollX, scrollY } = window;
				el.focus();
				window.scrollTo(scrollX, scrollY);
			}

			el.tabIndex = tabIndex;
			if (!hasTabIndex) {
				el.removeAttribute('tabindex');
			}
		},

		scrollToActiveDescendant(activedescendant) {
			if (activedescendant && !this.checkPreferReducedMotion()) {
				const target = this.data?.targets?.find?.((target) => {
					if (target.tocItemId === activedescendant) {
						return true;
					}
					return false;
				});
				const targetEl =
					target && target?.id === 'page-top'
						? document.getElementById('long-read-controller')
						: document.getElementById(target.id);
				if (targetEl) {
					scrollToElement(targetEl, {
						behavior:
							this.checkPreferReducedMotion() ||
							window.innerWidth < 1024
								? 'auto'
								: 'smooth',
					});
				}
			}
		},

		checkPreferReducedMotion() {
			return window.matchMedia('(prefers-reduced-motion: reduce)')
				.matches;
		},

		checkIsScrolledDown() {
			const { top = 0 } = this.$el?.getBoundingClientRect?.() || {};
			return top <= 40;
		},

		onScroll() {
			const { button } = this.$refs;
			if (this.checkIsScrolledDown()) {
				if (this.hasScrolledOnce) {
					button?.toggleOwnedLists?.(false);
				} else {
					this.hasScrolledOnce = true;
				}
			}

			if (this.hasBeenClosedOnce && button?.isExpanded === false) {
				window.removeEventListener('scroll', this.onScroll);
				window.removeEventListener('resize', this.onScroll);
			}
		},
	},
};
</script>

<style lang="postcss">
.c-page-progress-bar-toc {
	@apply relative w-full >=1024:w-auto max-w-full h-full;

	& > button {
		@apply py-12;
	}
}
.c-page-progress-bar-toc__label {
	left: calc(
		12px +
			var(
				--theme-horizontalSpacing-2xs-h,
				var(--theme-horizontalSpacing-2xs-h--sm)
			)
	);
}

@screen >=1200 {
	.c-page-progress-bar-toc {
		max-width: calc(
			var(--theme-layout-margin, var(--theme-layout-margin--sm)) +
				var(--theme-layout-column-of-12) * 3.5 +
				var(--theme-layout-gutter, var(--theme-layout-gutter--sm)) * 3
		);
	}
}

.c-page-progress-bar-toc__option-list {
	max-height: max(
		120px,
		90vh - var(--site-header-height, 0px) -
			var(
				--theme-verticalSpacing-2xl-v,
				var(--theme-verticalSpacing-2xl-v--sm)
			)
	);
}

.c-page-progress-bar-toc__item-text:before {
	@apply absolute inline-block bg-current rounded-full;
	content: '';
	width: 10px;
	height: 10px;
	top: calc(0.6em - 5px);
	right: calc(100% + 12px);

	scale: 0;
	transition: scale 0.1s ease-in-out;
}
.c-page-progress-bar-toc__item-text--selected:before {
	scale: 1;
}

.t-page-progress-bar-toc-enter-active,
.t-page-progress-bar-toc-leave-active {
	@apply transition-all duration-200;
}
.t-page-progress-bar-toc-enter,
.t-page-progress-bar-toc-leave-to {
	@apply transform translate-x-10 opacity-0;
}
.t-page-progress-bar-toc-enter {
	@apply -translate-x-10;
}

.t-page-progress-bar-toc__option-list-enter-active,
.t-page-progress-bar-toc__option-list-leave-active {
	@apply transition-all duration-200;
}
.t-page-progress-bar-toc__option-list-enter,
.t-page-progress-bar-toc__option-list-leave-to {
	@apply opacity-0;
	transform: translateY(-8px) scaleY(0.9);
}
</style>
