<template>
	<ul v-if="contactPersons.length" class="c-aside-contact-persons">
		<li
			v-for="(
				{
					id,
					key,
					label,
					name,
					details: { title = [], email, phone } = {},
					image,
					url,
				},
				index
			) in contactPersons"
			:key="key"
			class="w-216 flex-none"
		>
			<div class="w-full">
				<!-- Image section -->
				<LinkTile
					:is="url ? 'LinkTile' : 'div'"
					:to="url"
					:class="{
						group: url,
					}"
					:aria-labelledby="
						url ? `c-aside-contact-persons__item-name-${id}` : null
					"
				>
					<figure v-if="image" class="relative mb-xs/v">
						<!-- Flair -->
						<div class="absolute left-0 top-0 w-140 h-140">
							<div
								v-if="getFlairIndex(index) === 0"
								class="
									absolute
									left-4/5
									top-0
									w-1/2
									h-full
									bg-gray-light
									rounded-r-full
									transform
									transition-all
									duration-200
									group-hover:bg-theme-20
									group-focus-within:bg-theme-20
									group-hover:translate-x-1/20
									group-focus-within:translate-x-1/20
								"
							></div>
							<div
								v-if="getFlairIndex(index) === 1"
								class="
									absolute
									left-4/5
									bottom-2/5
									w-2/3
									h-2/3
									bg-gray-light
									transform
									transition-all
									duration-200
									group-hover:bg-theme-20
									group-focus-within:bg-theme-20
									group-hover:translate-x-1/20
									group-focus-within:translate-x-1/20
								"
							></div>
							<div
								v-if="getFlairIndex(index) === 2"
								class="
									absolute
									left-3/5
									top-2/5
									w-4/5
									h-2/3
									bg-gray-light
									transform
									transition-all
									duration-200
									group-hover:bg-theme-20
									group-focus-within:bg-theme-20
									group-hover:translate-x-1/20
									group-focus-within:translate-x-1/20
								"
							></div>
						</div>

						<!-- Image -->
						<BaseImg
							class="
								relative
								w-140
								h-140
								rounded-full
								object-cover
								overflow-hidden
							"
							style="background: rgba(0, 0, 0, 0.04)"
							:src="image.cropUrl || image.url"
							:alt="image.altText"
							:sizes="nuxtImgSizes('<1441:140px')"
							:width="image.width"
							:height="image.height"
							:ratio="1"
							load-color="rgba(0, 0, 0, 0.04)"
						/>
					</figure>

					<!-- Info -->
					<small v-if="label" class="block">
						<span
							class="
								inline-block
								font-medium
								uppercase
								text-label-sm text-red
							"
							v-text="label"
						></span>
					</small>

					<BaseH4
						v-if="name"
						:id="`c-aside-contact-persons__item-name-${id}`"
						tag="h2"
						class="text-card-title-sm-2 font-normal"
						v-html="name"
					/>

					<p
						v-if="title && title.length"
						class="
							font-light
							text-body-xs
							whitespace-pre-wrap
							mt-12
						"
					>
						<span
							v-for="(str, titleIndex) in title"
							:key="titleIndex"
							class="inline-block"
							v-text="
								titleIndex !== title.length - 1
									? str + ', '
									: str
							"
						></span>
					</p>
				</LinkTile>

				<div
					v-if="email || phone"
					class="mt-2xs/v flex flex-col gap-y-4"
				>
					<div v-if="phone" class="leading-0">
						<a
							:href="phone.link"
							class="text-label leading-1.2 text-red underline"
							v-text="phone.text"
						></a>
					</div>
					<div v-if="email" class="leading-0">
						<a
							:href="email.link"
							class="text-label leading-1.2 text-red underline"
							v-text="email.text"
						></a>
					</div>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
import LinkTile from '~/components/shared/LinkTile';
import { nuxtImgSizes } from '~/assets/js/utilities/helpers';

export default {
	name: 'AsideContactPersons',

	components: {
		LinkTile,
	},

	props: {
		contactPersons: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		flairSeed() {
			return +(
				this.contactPersons.filter((item) => item.id)?.[0]?.id || 0
			);
		},
	},

	methods: {
		nuxtImgSizes,

		getFlairIndex(index) {
			return (this.flairSeed + index) % 3;
		},
	},
};
</script>

<style lang="postcss">
.c-aside-contact-persons {
	@apply flex flex-wrap justify-start items-start gap-x-sm/h gap-y-sm/v;

	& .group {
		& picture {
			@apply rounded-full overflow-hidden;
			@apply relative isolate;
			clip-path: circle(50% at 50% 50%);
		}

		& img {
			@apply rounded-full overflow-hidden;
			transition: scale 0.2s ease-in-out, clip-path 0.2s ease-in-out;
			clip-path: circle(50% at 50% 50%);
		}

		&:hover img {
			scale: 1.05;
			clip-path: circle(47.5% at 50% 50%);
		}
	}
}
</style>
