<template>
	<div class="c-page-progress-bar">
		<!-- Progress bar -->
		<div
			role="progressbar"
			aria-label="placering på siden"
			:aria-valuenow="progress"
			aria-valuemin="0"
			aria-valuemax="100"
			class="absolute w-visual-screen h-full bg-red"
			:style="{
				'--progress': `${progress}%`,
			}"
		>
			<TransitionExt name="fade-in-out" duration="200">
				<div
					v-if="showProgressBar"
					class="bg-red-40 bg-opacity-30"
				></div>
			</TransitionExt>
		</div>

		<!-- Content -->
		<div
			class="
				relative
				flex flex-1
				self-stretch
				justify-start
				items-center
				w-full
				min-h-2xl/v
				max-w-layout-max
			"
		>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import TransitionExt from '../shared/TransitionExt.vue';
export default {
	name: 'PageProgressBar',
	components: { TransitionExt },

	data() {
		return {
			progress: 0,
			parentEl: null,

			frameRequest: null,
		};
	},

	computed: {
		showProgressBar() {
			// Hide if no parent element is found
			if (!this.parentEl) {
				return false;
			}

			// Hide if there's an expanded TOC within
			if (
				this.$children.find((child) => {
					return child.$options.name === 'PageProgressBarToc';
				})?.isExpanded
			) {
				return false;
			}

			return true;
		},
	},

	mounted() {
		// Find the parent that has registered the component
		let parent = this.$parent;
		while (
			parent &&
			!Object.keys(parent?.$options?.components ?? {}).includes(
				'PageProgressBarToc'
			)
		) {
			parent = parent.$parent;
		}
		this.parentEl = parent?.$el;

		this.updateProgress();
		window.addEventListener('scroll', this.updateProgress);
		window.addEventListener('resize', this.updateProgress);
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.updateProgress);
		window.removeEventListener('resize', this.updateProgress);
	},

	methods: {
		updateProgress() {
			window.cancelAnimationFrame(this.frameRequest);
			this.frameRequest = window.requestAnimationFrame(() => {
				if (this.parentEl) {
					// Update progress
					const { clientTop, clientHeight, scrollHeight } =
						this.parentEl;
					const { scrollY, innerHeight } = window;
					const height = Math.max(clientHeight, scrollHeight);

					const progress =
						Math.round(
							((scrollY - clientTop) /
								Math.max(1, height - innerHeight)) *
								100 *
								100
						) / 100;
					this.progress = Math.max(0, Math.min(100, progress));

					// Set the scroll-margin-top on the immediate parent element
					if (this.$el) {
						const { clientHeight: barHeight } = this.$el;
						const immediateParent = this.$el.parentElement;
						if (window.innerWidth < 1024) {
							immediateParent.style.setProperty(
								'--scroll-margin-top',
								`calc(${barHeight}px + var(--theme-verticalSpacing-site-header-slim, var(--theme-verticalSpacing-site-header-slim--sm)))`
							);
						} else {
							immediateParent.style.setProperty(
								'--scroll-margin-top',
								`calc(${
									barHeight + 20
								}px + var(--site-header-height, var(--theme-verticalSpacing-site-header-slim, var(--theme-verticalSpacing-site-header-slim--sm))))`
							);
						}
					}
				}
			});
		},
	},
};
</script>

<style lang="postcss">
.c-page-progress-bar {
	@apply sticky left-0 w-full h-full z-10;
	@apply flex justify-center items-center text-white;
	top: var(--site-header-height, 0px);

	& * {
		scroll-margin-top: var(--site-header-height, 0px);
	}

	& [role='progressbar'] > div {
		width: var(--progress, 0%);
		height: 100%;
	}
}

@screen print {
	.c-page-progress-bar {
		display: none;
	}
}
</style>
