<template>
	<div
		class="c-header-graphic"
		:class="[`c-header-graphic--image-${computedIndex}`]"
		aria-hidden="true"
		:data-index="computedIndex"
	>
		<Component :is="`SvgImage${computedIndex}`" class="o-svg-themify" />
	</div>
</template>

<script>
const svgs = {
	// Frontpage / other pages
	SvgImage0: () =>
		import('~/assets/svgs/header-graphics/graphic-0.svg?inline'),
	// Other pages
	SvgImage1: () =>
		import('~/assets/svgs/header-graphics/graphic-1.svg?inline'),
	// Frontpage
	SvgImage2: () =>
		import('~/assets/svgs/header-graphics/graphic-2.svg?inline'),
	// Other pages
	SvgImage3: () =>
		import('~/assets/svgs/header-graphics/graphic-3.svg?inline'),
	// Other pages
	SvgImage4: () =>
		import('~/assets/svgs/header-graphics/graphic-4.svg?inline'),
};

export default {
	name: 'HeaderGraphic',

	components: {
		...svgs,
	},

	props: {
		index: {
			type: [Number, String],
			default: -1,
		},
	},

	computed: {
		computedIndex() {
			if (this.index >= 0) {
				return this.index % Object.keys(svgs).length;
			}
			return (this.$store.state.site?.id || 0) % Object.keys(svgs).length;
		},
	},
};
</script>

<style lang="postcss">
.c-header-graphic {
	@apply flex justify-center items-center w-full isolate overflow-hidden bg-theme;
	aspect-ratio: 413 / 197;

	& svg {
		@apply flex-shrink-0 flex-grow-0;
		display: block;
		width: 100%;
		height: 100%;
	}
}

.c-header-graphic--image-0 {
	@apply justify-end;

	& svg {
		margin-right: -96px;
		width: calc(100% + 96px);
		height: 175%;
		min-width: 1440px;
		min-height: 510px;
	}
}

.c-header-graphic--image-1 {
	@apply justify-end items-start;

	& svg {
		margin-top: -8px;
		margin-right: -22px;
		width: auto;
		height: 145%;
		aspect-ratio: 1.4;
		max-height: 83vw;
	}
}

.c-header-graphic--image-2 {
	@apply justify-end items-start;

	& svg {
		height: auto;
		min-height: 100%;
		width: calc(
			var(--theme-layout-column-of-12) * 11 +
				var(--theme-layout-gutter, var(--theme-layout-gutter--sm)) * 10
		);
		aspect-ratio: 1.48;
	}
}
@screen <768 {
	.c-header-graphic--image-2 {
		& svg {
			width: 130%;
		}
	}
}
@screen >=1441 {
	.c-header-graphic--image-2 {
		& svg {
			width: calc(
				(
						var(--visual-viewport-width, 100vw) -
							(
								var(--theme-layout-column-of-12) * 12 +
									var(
										--theme-layout-gutter,
										var(--theme-layout-gutter--sm)
									) * 11
							)
					) / 2 +
					(
						var(--theme-layout-column-of-12) * 11 +
							var(
								--theme-layout-gutter,
								var(--theme-layout-gutter--sm)
							) * 10
					)
			);
		}
	}
}

.c-header-graphic--image-3 {
	@apply justify-end;

	& svg {
		min-width: 800px;
	}
}

.c-header-graphic--image-4 {
	@apply justify-end;

	& svg {
		width: auto;
		max-width: 150%;
		height: 100%;
		aspect-ratio: 1132 / 583;
	}
}
</style>
