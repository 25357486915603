var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"c-page-header",class:("c-page-header--layout-" + _vm.layout)},[_c('div',{staticClass:"c-page-header__main relative px-layout-margin text-white",class:{
			'pt-site-header': !_vm.$scopedSlots.custom,
			'pb-sm/v':
				['default', 'publication'].includes(_vm.layout) &&
				!_vm.$scopedSlots.custom,
			'grid grid-cols-12 pb-0':
				_vm.layout === 'list' && !_vm.$scopedSlots.custom,
		}},[_vm._t("custom",function(){return [(_vm.headerGraphic > -2)?_c('HeaderGraphic',{staticClass:"c-page-header__header-graphic",staticStyle:{"z-index":"-1"},attrs:{"index":_vm.headerGraphic}}):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.top || _vm.computedLabel || _vm.computedAreas)?_c('div',{staticClass:"mb-64",class:{
					'>=1024:mb-24': ['default', 'publication'].includes(
						_vm.layout
					),
					'col-span-12': _vm.layout === 'list',
				}},[_vm._t("top",function(){return [_c('div',{staticClass:"flex flex-wrap items-center gap-8"},[(_vm.computedLabel)?_c('small',{staticClass:"pr-3xs/h"},[_c('span',{staticClass:"\n\t\t\t\t\t\t\t\t\tinline-block\n\t\t\t\t\t\t\t\t\tfont-medium\n\t\t\t\t\t\t\t\t\tuppercase\n\t\t\t\t\t\t\t\t\ttext-label-sm\n\t\t\t\t\t\t\t\t",domProps:{"textContent":_vm._s(_vm.computedLabel)}}),_vm._v(" "),(_vm.computedDate)?_c('span',{staticClass:"\n\t\t\t\t\t\t\t\t\tc-page-header__date-stamp\n\t\t\t\t\t\t\t\t\tinline-block\n\t\t\t\t\t\t\t\t\tfont-medium\n\t\t\t\t\t\t\t\t\ttext-label-sm\n\t\t\t\t\t\t\t\t\tuppercase\n\t\t\t\t\t\t\t\t",domProps:{"textContent":_vm._s(_vm.computedDate)}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.computedAreas)?_c('AreaReferences',{staticClass:"hidden >=1024:contents",attrs:{"items":_vm.computedAreas,"invert":""}}):_vm._e()],1)]})],2):_c('div',{staticClass:"pt-48 >=1024:hidden"}),_vm._v(" "),(_vm.intro.title)?_c('div',{class:{
					'col-span-12 >=1024:col-span-4': _vm.layout === 'list',
				}},[(_vm.iconUrl)?_c('AreaIcon',{staticClass:"\n\t\t\t\t\t\tinline-block\n\t\t\t\t\t\tw-header-icon\n\t\t\t\t\t\th-header-icon\n\t\t\t\t\t\talign-baseline\n\t\t\t\t\t\tmr-xs/h\n\t\t\t\t\t",staticStyle:{"margin-block":"calc(4px - 0.4em)"},attrs:{"invert":"","src":_vm.iconUrl}}):_vm._e(),_vm._v(" "),_c('BaseH1',{staticClass:"inline",attrs:{"small":_vm.layout === 'list'},domProps:{"innerHTML":_vm._s(_vm.intro.title)}})],1):_vm._e(),_vm._v(" "),(
					(_vm.intro.teaser && _vm.layout === 'list') ||
					(_vm.computedSubTitle &&
						['default', 'publication'].includes(_vm.layout)) ||
					_vm.$scopedSlots.afterTeaser ||
					_vm.computedAuthors
				)?_c('div',{staticClass:"mt-20",class:{
					'>=1024:mt-sm/v': ['default', 'publication'].includes(
						_vm.layout
					),
					'>=1024:mt-0 col-span-12 >=1024:col-start-5 >=1024:col-span-8 >=1024:pl-16':
						_vm.layout === 'list',
				}},[(_vm.intro.teaser && _vm.layout === 'list')?_c('p',{staticClass:"block text-teaser whitespace-pre-wrap",domProps:{"innerHTML":_vm._s(_vm.intro.teaser)}}):_vm._e(),_vm._v(" "),(
						_vm.computedSubTitle &&
						['default', 'publication'].includes(_vm.layout)
					)?_c('p',{staticClass:"block text-teaser whitespace-pre-wrap",class:{
						'font-light': ['default', 'publication'].includes(
							_vm.layout
						),
					},domProps:{"innerHTML":_vm._s(_vm.computedSubTitle)}}):_vm._e(),_vm._v(" "),(_vm.$scopedSlots.afterTeaser || _vm.computedAuthors)?_vm._t("afterTeaser",function(){return _vm._l((_vm.computedAuthors),function(group,index){return _c('small',{key:index,staticClass:"\n\t\t\t\t\t\t\tflex flex-wrap\n\t\t\t\t\t\t\t>=1024:flex-nowrap\n\t\t\t\t\t\t\titems-baseline\n\t\t\t\t\t\t\tgap-x-12\n\t\t\t\t\t\t\talign-baseline\n\t\t\t\t\t\t\ttext-body-xs\n\t\t\t\t\t\t\tfont-medium\n\t\t\t\t\t\t\tmt-xs/v\n\t\t\t\t\t\t"},[(group.title)?_c('h2',{staticClass:"uppercase",domProps:{"textContent":_vm._s(group.title + ':')}}):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"\n\t\t\t\t\t\t\t\tflex\n\t\t\t\t\t\t\t\titems-start\n\t\t\t\t\t\t\t\tflex-wrap\n\t\t\t\t\t\t\t\tgap-x-12\n\t\t\t\t\t\t\t\talign-baseline\n\t\t\t\t\t\t\t"},[_vm._l((group.items.filter(
									function (item) { return item.value; }
								)),function(item,itemIndex){return [_vm._l((item.value),function(value,nameIndex){return [(value.url)?_c('li',{key:itemIndex +
											'-' +
											nameIndex +
											'-link'},[_c('NuxtLinkExt',{staticClass:"underline",staticStyle:{"text-underline-offset":"0.2em"},attrs:{"to":value.url},domProps:{"textContent":_vm._s(
												[
													value.name,
													value.comment &&
														("(" + (value.comment) + ")") ]
													.filter(Boolean)
													.join(' ')
											)}})],1):_c('li',{key:itemIndex + '-' + nameIndex,domProps:{"textContent":_vm._s(
											[
												value.name,
												value.comment &&
													("(" + (value.comment) + ")") ]
												.filter(Boolean)
												.join(' ')
										)}})]})]})],2)])})}):_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{class:{
					'col-span-12': _vm.layout === 'list',
				}},[_vm._t("default",function(){return [(_vm.computedAreas)?_c('AreaReferences',{staticClass:">=1024:hidden mt-2xs/v",attrs:{"items":_vm.computedAreas,"invert":""}}):_vm._e()]})],2)]})],2),_vm._v(" "),(
			_vm.intro.image &&
			['default', 'publication'].includes(_vm.layout) &&
			!_vm.$scopedSlots.custom
		)?_c('figure',{staticClass:"flex flex-col justify-center items-center"},[_c('BaseImg',{staticClass:"w-visual-screen min-h-hero-image max-h-hero-image",attrs:{"src":_vm.intro.image.cropUrl || _vm.intro.image.url,"alt":_vm.intro.image.altText,"sizes":_vm.nuxtImgSizes('<1441:100vw'),"width":_vm.intro.image.width,"height":_vm.intro.image.height,"ratio":1440 / 658,"img-attrs":{
				fetchpriority: 'high',
				class: 'object-cover',
			},"credits":_vm.intro.image.credits,"quality":80}}),_vm._v(" "),(_vm.intro.imageText)?_c('figcaption',{staticClass:"\n\t\t\t\tw-full\n\t\t\t\tpx-layout-margin\n\t\t\t\tpy-2xs/v\n\t\t\t\ttext-body-xs text-gray-darker\n\t\t\t",domProps:{"textContent":_vm._s(_vm.intro.imageText)}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }