var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"c-page-progress-bar-toc"},[(_vm.tocToTop)?_c('LongReadTarget',{attrs:{"container-id":"page-top","title":_vm.tocToTop,"forced-order":[0, 0, 0, 0, 0, 0]}}):_vm._e(),_vm._v(" "),_c('DropdownButton',{ref:"button",staticClass:"\n\t\t\ttext-list-title\n\t\t\tpx-layout-margin\n\t\t\tw-full\n\t\t\t>=1024:w-auto\n\t\t\th-full\n\t\t\tflex\n\t\t\titems-center\n\t\t\ttext-left\n\t\t\ttransition-colors\n\t\t\tduration-200\n\t\t",class:{
			'bg-red-80': _vm.isExpanded,
		},attrs:{"id":"table-of-contents","aria-labelledby":"table-of-contents","aria-owns":("c-page-progress-bar-toc__option-list-" + _vm._uid),"value":_vm.activeID},on:{"focus":_vm.onFocus,"state:toggle":_vm.onStateToggle,"change":_vm.selectTarget,"update:activedescendant":_vm.scrollToActiveDescendant}},[_c('span',{staticClass:"relative inline-flex items-center"},[_c('SvgIconWedge',{class:[
					'flex-shrink-0 flex-grow-0',
					'mr-2xs/h w-12 h-8 mt-1',
					{ 'transform -rotate-180 -mt-1': _vm.isExpanded } ]}),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"page-progress-bar-toc","duration":"200"}},[_c('span',{key:_vm.buttonText,staticClass:"c-page-progress-bar-toc__label absolute",domProps:{"innerHTML":_vm._s(_vm.buttonText)}})]),_vm._v(" "),_c('span',{key:"spacetaker",staticClass:"invisible",domProps:{"innerHTML":_vm._s(_vm.longestButtonText)}})],1)]),_vm._v(" "),_c('Transition',{attrs:{"name":"t-page-progress-bar-toc__option-list"}},[_c('DropdownOptionList',{staticClass:"\n\t\t\t\tc-page-progress-bar-toc__option-list\n\t\t\t\tbg-red-80\n\t\t\t\tabsolute\n\t\t\t\tleft-0\n\t\t\t\ttop-full\n\t\t\t\tw-full\n\t\t\t\tpt-6\n\t\t\t\tpb-lg/v\n\t\t\t\toverscroll-none\n\t\t\t\toverflow-y-auto\n\t\t\t\torigin-top\n\t\t\t",attrs:{"id":("c-page-progress-bar-toc__option-list-" + _vm._uid)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var activedescendant = ref.activedescendant;
			var selectedValues = ref.selectedValues;
			var isNavigatingByKeyboard = ref.isNavigatingByKeyboard;
return _vm._l((_vm.data.targets),function(item,index){return _c('DropdownOption',{key:item.tocItemId,staticClass:"text-body px-layout-margin hover:bg-red-90",class:{
					'bg-red-90':
						activedescendant === item.tocItemId &&
						isNavigatingByKeyboard,
				},attrs:{"id":item.tocItemId,"value":item.id}},[_c('span',{staticClass:"\n\t\t\t\t\t\tinline-block\n\t\t\t\t\t\tw-full\n\t\t\t\t\t\tpl-24\n\t\t\t\t\t\t>=768:pl-0\n\t\t\t\t\t\tpb-12\n\t\t\t\t\t\tborder-b border-white\n\t\t\t\t\t\thover:border-opacity-100\n\t\t\t\t\t",class:{
						'pt-12': index === 0 && !_vm.tocToTop,
						'pt-20': index > 0 || _vm.tocToTop,
						'border-opacity-50':
							!(
								activedescendant === item.tocItemId &&
								isNavigatingByKeyboard
							) &&
							!(
								selectedValues &&
								selectedValues.includes(item.id)
							),
					}},[_c('span',{staticClass:"c-page-progress-bar-toc__item-text relative",class:{
							'c-page-progress-bar-toc__item-text--selected':
								selectedValues &&
								selectedValues.includes(item.id),
						}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t")])])])})}}],null,false,1116115292)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }